import { AtSignIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  const techStackColor = useColorModeValue("gray.600", "gray.300");
  return (
    <Layout>
      <SEO title="Xia Deneng | Fullstack Developer" />
      <Link
        display="inline-flex"
        alignItems="center"
        fontFamily="mono"
        fontSize="xl"
        isExternal
        _focus={{ outline: "none" }}
        href="https://github.com/xneng"
      >
        Xia Deneng
        <AtSignIcon mx={2} />
        xneng
      </Link>
      <Text fontSize="lg" my={4}>
        Fullstack Software Engineer
      </Text>
      <Box fontFamily="mono" fontSize="sm">
        <VStack spacing={2} align="stretch" color={techStackColor}>
          <Text>Backend: Python, Go</Text>
          <Text>Frontend: React, TypeScript</Text>
          <Text>DevOps: Ansible, Docker, K8s</Text>
        </VStack>
        <Flex mt={4} alignItems="center">
          <EmailIcon mr={2} /> i@neng.dev
        </Flex>
      </Box>
    </Layout>
  );
};

export default IndexPage;
